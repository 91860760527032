import React, { HTMLProps } from "react";
import { SharedUrls } from "@accurx/shared";
import { Text, Link, Flex, Item } from "@accurx/design";
import { StyledHeaderBackground, StyledItem } from "./Header.styles";
import Image from "next/legacy/image";
import nhsLogoOnWhite from "../../../../public/NhsLogoOnWhite.svg";
import { useOrganisation } from "../../context/OrganisationContext";
import { useContent } from "../../context/ContentContext";
import { useNhsApp } from "../../hooks";
import { NHSHeader } from "./NHSHeader";

type HeaderProps = {
    props?: HTMLProps<"div">;
};

export const Header = ({ ...props }: HeaderProps): JSX.Element => {
    const { organisationSettings } = useOrganisation();
    const organisationAddress = organisationSettings?.organisationAddress;
    const { meta } = useContent();
    const isUsingNhsApp = useNhsApp();

    if (isUsingNhsApp && navigator.userAgent.includes("nhsapp")) {
        return <NHSHeader {...props} />;
    }

    return (
        <StyledHeaderBackground lang="en" dir="ltr">
            <Flex flexDirection="column" justifyContent="space-between">
                <Flex flexDirection="row">
                    <StyledItem>
                        <Image src={nhsLogoOnWhite} alt="nhs logo" />
                    </StyledItem>
                    <Item>
                        <Text variant="subtitle" colour="white" skinny>
                            {organisationSettings?.organisationName}
                        </Text>
                        {organisationAddress?.addressOne && (
                            <>
                                <Text colour="white" skinny>
                                    {organisationAddress.addressOne},{" "}
                                    {organisationAddress.addressTwo}
                                </Text>
                                <Text colour="white" skinny>
                                    {organisationAddress.town},{" "}
                                    {organisationAddress.postCode}
                                </Text>
                            </>
                        )}
                        {!organisationAddress?.addressOne &&
                            organisationAddress?.addressTwo && (
                                <>
                                    <Text colour="white" skinny>
                                        {organisationAddress.addressTwo},{" "}
                                    </Text>
                                    <Text colour="white" skinny>
                                        {organisationAddress.town},{" "}
                                        {organisationAddress.postCode}
                                    </Text>
                                </>
                            )}
                    </Item>
                </Flex>
                <Flex
                    alignItems="end"
                    justifyContent="flex-end"
                    flexDirection="row"
                >
                    <Link
                        href={SharedUrls.PatientPrivacy}
                        theme="dark"
                        openInNewTab
                    >
                        <Link.Text text={meta.header.accurxLink} />
                        <Link.Icon />
                    </Link>
                </Flex>
            </Flex>
        </StyledHeaderBackground>
    );
};
