import { useRouter } from "next/router";
import { useEffect } from "react";
import { getPatientInitiatedSpecificRoute } from "../constant/paths";
import { useOrganisation } from "../context/OrganisationContext";
import { useContent } from "../context/ContentContext";

export const useRedirectToBasePageIfPageIsInvalid = () => {
    const { specificFlow } = useContent();
    const { organisationSettings } = useOrganisation();

    const isPageValid = specificFlow !== null && organisationSettings !== null;

    const router = useRouter();

    useEffect(() => {
        if (isPageValid) return;
        router.push(
            getPatientInitiatedSpecificRoute(
                router.query.organisationCode,
                router.query.slug,
                "",
            ),
        );
    }, [isPageValid, router]);

    if (!isPageValid) return false;

    return isPageValid;
};
