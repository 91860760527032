import { ParsedUrlQuery, stringify } from "querystring";

export const PATHS = {
    confirmNotEmergency: "/confirm-not-emergency",
    notFound: "/not-found",
    questions: "/questions",
    flows: "/flows",
    recipient: "/recipient",
    patientDetails: "/patient-details",
    photoUploadRequirements: "/photo-upload/requirements",
    photoUploadTips: "/photo-upload/tips",
    photoUploadPreview: "/photo-upload/preview",
    review: "/review",
    confirm2FA: "/confirm-2fa",
    confirmation: "/confirmation",
};

export const getPatientInitiatedRoute = (
    organisationCode: string | undefined | string[],
    path: string,
    query?: ParsedUrlQuery,
): string => {
    if (typeof organisationCode === "string")
        return `/patient-initiated/${organisationCode}${path}${getQueryString(
            query,
        )}`;
    return PATHS.notFound;
};

export const getPatientInitiatedSpecificRoute = (
    organisationCode: string | undefined | string[],
    slug: string | undefined | string[],
    path: string,
): string => {
    if (typeof organisationCode === "string")
        return `/patient-initiated/${organisationCode}/flow/${slug}${path}`;
    return PATHS.notFound;
};

const getQueryString = (query?: ParsedUrlQuery): string => {
    if (!query) return "";
    const { organisationCode, ...rest } = query;
    const stringified = stringify(rest);
    return stringified.length > 0 ? `?${stringified}` : "";
};
